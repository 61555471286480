<template>
  <div>
    <ClientUserTable
      :userList="userList"
      :pageLoader="pageLoader"
      :error="error"
      type="client"
      :clientId="clientInfo.id"
    >
    </ClientUserTable>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/system/breadcrumbs.module";
import { POPULATE_CLIENT_USER_LIST } from "@/core/services/store/core/user.module";
import ClientUserTable from "@/view/content/users/UserTable";

export default {
  name: "ViewClientUsers",

  components: {
    ClientUserTable
  },

  data() {
    return {
      pageLoader: {
        componentsCompleted: 0,
        componentsRequired: 1
      },
      error: {
        active: false,
        message: ""
      }
    };
  },

  mounted() {
    let protocol = process.env.NODE_ENV == "local" ? "http://" : "https://";
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `${this.$t("NAVIGATION.ROOT_MENU.CLIENTS")}`, route: protocol + 'admin.' + process.env.VUE_APP_API_ROOT + "/#/clients/view" },
      { title: this.clientInfo.name, route: protocol + this.clientInfo.key + ".admin." + process.env.VUE_APP_API_ROOT + "/#/client/projects" },
      { title: `${this.$t("NAVIGATION.CLIENT_MENU.USERS")}` }
    ]);
    this.fetchClientUsers();
  },

  methods: {
    fetchClientUsers() {
      let payload = {
        clientId: this.clientInfo.id,
        params: {
          paginate: false,
          clients: "with",
          clientRole: "with"
        }
      };
      this.$store
        .dispatch(POPULATE_CLIENT_USER_LIST, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the client user list, please check back later or contact the helpdesk";
        });
    }
  },

  computed: {
    ...mapGetters(["userList", "clientInfo"])
  }
};
</script>
